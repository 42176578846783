.prescreen_preview_main_container {
    line-break: anywhere;

    body {
        background-color: #eceef3;
        padding: 0px;
        margin: 0px;
    }

    a {
        text-decoration: none;
    }

    .prescreen_preview_container {
        max-width: 850px;
        height: fit-content;
        background: #FFFFFF;
        box-shadow: -4px -5px 54px -17px rgba(0, 0, 0, 0.13);
        border-radius: 14px;
        margin: 0px auto;
        font-family: 'Poppins';
        font-style: normal;
        letter-spacing: 0.005em;
    }

    .prescreen_preview_container>div {
        padding-left: 38px;
        padding-right: 10px;
    }

    .preview_title {
        font-weight: 600;
        font-size: 20px;
        color: #3D3D3D;
        line-height: 60px;
    }

    .preview_role {
        font-weight: 400;
        font-size: 14px;
        color: #3D3D3D;
        margin-top: -38px;
    }

    hr {
        border: 1px solid #CCD0DB;
    }

    .prescreen_preview_details {
        padding-bottom: 20px;

        h2 {
            font-weight: 600;
            font-size: 17px;
            color: #123FBC;
        }

        .configurations-label {
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 13px;
            color: #3D3D3D;
            margin-bottom: 5px;
        }

        .configurations-value {
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 14px;
            line-height: 124.4%;
            letter-spacing: 0.005em;
            color: #3D3D3D;
            margin-left: 5px;
        }

        .question-text {
            font-family: 'Poppins';
            font-weight: 500;
            font-size: 14px;
            color: #3D3D3D;
            margin-bottom: 5px;

        }

        .question-code {
            padding: 5px;
            white-space: pre-wrap;
            background: #F5F5F5;
            border-radius: 4px;
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 10px;
            line-height: 15px;
            color: #282828;
        }

        .question-tags {
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 11px;
            line-height: 124.4%;
            color: #535353;
        }

        .difficulty {
            padding-top: 0.25em;
            padding-left: 2.1em;
            padding-right: 0.25em;
            font-size: 11px !important;
            font-weight: 600 !important;
            line-height: 14px;
            letter-spacing: 0.005em;
            text-align: left;
        }

        .fg-green {
            color: #47AC45;
        }

        .fg-gold {
            color: #C9B861;
        }

        .fg-red {
            color: #B60000;
        }

        .selected-answer {
            font-weight: 600;
            background: #DBF0DB;
            border-radius: 4px;
        }

        .correct-answer {
            font-family: 'Poppins';
            font-weight: 600;
            font-size: 12px;
            color: #009B00;
        }

    }
}

.prescreen_preview_box {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;

    .preview_topBar {
        background: #123FBC;
        border-radius: 2px;
        padding: 20px 60px;
        height: 61px;
        box-shadow: -1px -1px 30px -3px rgba(0, 0, 0, 0.06);
        margin: -20px -20px 0 -18px;
        padding-left: 100px;
        font-family: 'Poppins';
        font-style: normal;
        color: #FFFFFF;

        .iv-logo {
            border-radius: 4px;
            height: 30px;
            margin-top: -6px;
        }

        .navbar-text {
            font-weight: 700;
            font-size: 20px;
            line-height: 0%;
            text-align: center;
            letter-spacing: 0.095em;
            text-transform: uppercase;
        }

        .share-link {
            width: 130px;
            height: 35px;
            background: rgba(0, 130, 250, 0.25);
            border-radius: 8px;
            margin-top: -8px;
            font-weight: 400;
            font-size: 14px;
            padding: 6px 10px;
            display: flex;
            gap: 10px;
            cursor: pointer;
        }
    }



}