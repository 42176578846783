.edit-external-form-parentDiv {
    width: 100%;
    
    .question-titles-technical {
        height: 12px !important;
        font-family: 'Poppins' !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 124.4% !important;
        letter-spacing: 0.005em !important;
        color: #3D3D3D !important;
        margin-bottom: 15px !important;
        margin-top: 25px !important;
    }

    .add-Button-technical {
        height: 32px;
        width: 32px;
        color: white;
        background-color: #3D3D3D;
        border-radius: 4px;
        font-size: 20px;
        margin-left: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .minute p {
        color: gray !important;
        font-weight: 600 !important;
    }


    .minus-button-technical {
        height: 32px;
        width: 32px;
        margin-left: 5px;
    }


    .checkbox-label-technical {
        height: 18px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.025em;
        color: #3D3D3D;
    }



    .add-button-tag {
        width: 28px;
        height: 23px;
        background-color: #D9D9D9;
        border-radius: 4px;
        color: black;
        align-items: flex-end;
        margin-top: 5px;
        padding-left: 4px;
        cursor: pointer;
    }



    .renderDropDown {
        max-width: 10em;
    }

    .tags-parent {
        .tag {
            color: white;
            display: flex;
            height: 24px;
            justify-content: space-between;
            margin: 2px;
            padding: 2px 5px;
            border-radius: 6px;
            background-color: #3D3D3D;

            .tag-text-technical {
                font-family: Poppins;
                font-size: 14px;
                margin: 2px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: 0.025em;
                text-align: left;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 90px;
                width: fit-content;
            }

            .cross-icon {
                margin: 4px;
                height: 14px;
                width: 14px;
            }

        }

    }
}

.option-div{
    margin-left: 0%;
    margin-top: 5px;

    .option-title{
      text-transform:capitalize;
    }

    .option-div-text{
      width: 90%;
      margin: 5px 0 5px 10px;
    }
    
    .option-container{
      display: flex;
      width: 95%;
      align-items: center;
    }

    .radio-button{
      width: 16px;
      height: 16px;
      margin-top: -8px;
    }
  }

  .questionbg{
    background: #F0F3FF;
    border-radius: 14px;
    padding: 20px;
    width: 100%;
    margin-top: 20px;
  }

  .addoption{
    background: #FFFFFF;
    border-radius: 4px;
    padding: 5px;
    width: fit-content;

  }