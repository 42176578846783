.prescreen_list_main_container {
    .main_container {
        margin-left: 45px;
        overflow: scroll;
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        font-family: 'Poppins';
        letter-spacing: 0.005em;
        font-size: 12px;
    }
    .text-decoration-none {
        text-decoration: none;
    }
    
    .text-decoration-none:hover {
        text-decoration: none;
    }
    .table-container {
        box-shadow: none;
        border: none;
    }

    tr,
    td,
    th {
        border: none;
        box-shadow: none;
    }

    .custom-history-input {
        height: 38px;
        width: 200px;
        border-radius: 4px;
        font-size: 14px;
        background-color: #f8f8f8;
        box-sizing: border-box;
    }

    .create-btn-main {
        position: absolute;
        margin-top: 0px;
        right: 65px;
    }

    .edit-icon {
        color: #2152BF;
        cursor: pointer;
        white-space: nowrap;
    }

    .edit-icon>a:hover {
        text-decoration: none;
    }


    .nmt-20 {
        margin-top: -20px;
    }

    .fg-gray {
        color: #838383;
    }

    .font-16 {
        font-size: 16px;
    }

    .font-14 {
        font-size: 14px;
    }

    .font-13 {
        font-size: 13px;
    }

    .bold-400 {
        font-weight: 400;
    }

    .bold-500 {
        font-weight: 500;
    }

    .bold-600 {
        font-weight: 600;
    }

}