.question-bank-accordion {
  .font-style {
    font-family: "Poppins";
    font-style: normal;
    letter-spacing: 0.005em;
    color: #3d3d3d;
  }
  .col-style {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  .role {
    font-weight: 600;
    font-size: 12px;
    color: #3D3D3D;
    font-family: 'Poppins';
    font-style: normal;
  }
  .zero-col {
    align-items: start;
    width: 5%;
    display: block;
  }
  .first-col {
    display: block;
    width: 45%;
    margin-top: 9px;
  }
  .column-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 55%;
  }
  .second-col {
    display: block;
    margin-top: 9px;
  }
  .third-col {
    display: block;
    margin-top: 9px;
    margin-right: 20px;
  }
  .fourth-col {
    display: block;
    margin-top: 10px;
  }
  .collapse-content {
    width: 90%;
    margin-left: 8%;
  }
  .question-link {
    text-decoration: underline;
    font-size: 11px;
    font-weight: 400;
    color: #123fbc;
    margin-top: 0.8%;
    cursor: pointer;
    display: block;
  }
  .fg-easy {
    color: #147d18;
  }
  .fg-medium {
    color: #3d3d3d;
  }
  .fg-hard {
    color: #b60000;
  }
  .font-12 {
    font-size: 12px;
  }
  .font-13 {
    font-size: 13px;
  }
  .font-11 {
    font-size: 11px;
  }
  .font-400 {
    font-weight: 400;
  }
  .font-600 {
    font-weight: 600;
  }
  .fg-gray {
    color: #6b6b6b;
  }
}

.no_result_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #2b2b2b;
}
.no_result_sub_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #2b2b2b;
  width: 70%;
}
