.prescreen-form {
    width: 100%;

    .mg-8{
        margin-bottom: 8px;
    }

    .mt-16{
        margin-top: 16px;
    }

    .blue-text{
        color: #1844AD;
        text-decoration: underline;
        font-weight: 600;
    }
    
    .label-text{
        font-family: 'Poppins';
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 0.025em;
        color: #696969; 
        padding: 8px 0;
      }
  
      .select-dropdown{
        height: 30px;
        width: 142px;
        margin-bottom: 14px;
        font-size: 13px;
        color: black;
      }
  
      .disable-dropdown{
        pointer-events: none;
      }
  
      .test-name-input{
        width: 443px;
        margin-bottom: 8px;
        border-radius: 4px;
        border: 1px solid #C9C9C9;
      }
      .MuiOutlinedInput-notchedOutline{
        border: none;
      }
  

    .header {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 18px;
        line-height: 124.4%;
        letter-spacing: 0.005em;
        color: #123FBC;
    }

    .sub-heading {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 14px;
        line-height: 124.4%;
        letter-spacing: 0.005em;
        color: #6B6B6B;
        margin-top: 20px;

        .edit-result-criteria{
            float: right;
            font-weight: 400;
            font-size: 12px;
            line-height: 124.4%;
            display: flex;
            gap: 6px;
            cursor: pointer;
            margin-right: 50px;
            letter-spacing: 0.005em;
            text-decoration-line: underline;
            color: #123FBC;
        }

    }

    .heading-others {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 12px;
        line-height: 124.4%;
        letter-spacing: 0.005em;
        color: #595959;
        margin: 15px 0px 10px 0px;
    }

    .heading-light {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 12px;
        line-height: 124.4%;
        letter-spacing: 0.005em;
        color: #848484;

        .span{
            font-weight: 600;
            color: #3D3D3D;
        }
    }

    .warning-text{
        font-weight: 400;
        font-size: 12px;
        color: #BC2612;
    }

.renderDropDown{
    max-width:10em;
}


    .topicContainer{
        margin-top: 1em;
        .questionTopic{
            margin-bottom: -0.25em;
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0.005em;
            text-align: left;
            color:rgba(18, 63, 188, 1);
        }
    }
    .heading-3 {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 12px;
        line-height: 124.4%;
        letter-spacing: 0.005em;
        color: #3D3D3D;
    }

    .question-numbers {
        width: 50px;
        margin-left: 4px;
        margin-right: 4px;
    }

    .mb-10 {
        margin-bottom: 10px;
    }

    .mt-6 {
        margin-top: 6px;
    }

    .evaluationsList-box{
        display: flex;

        .evaluationBox-leftSide{
            display: flex;
            align-items: center;
            width:70%;

            .editIcon{
                height: 16px;
                width: 12px;
                margin-left: 10px;
                cursor: pointer;

                img{
                    margin-bottom: 9px;
                }
            }
        }
    }

    .evaluationTextField {
        width: 60%;
    }


    .textField-margins{
        margin-left: 14px;
        margin-top: 8px;
        margin-bottom: 15px ;
    }

    .testFilled-margins{
        margin-bottom: 15px ;
        margin-left: 23px;
        margin-top: -4px;
    }

    .evaluationTextFilled{
        max-width: 60%;
        margin-left: 20px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 124.4%;
        letter-spacing: 0.005em;
        color: #858181;

    }

    .evluationBtn{
        margin-left: 10px;
    }

    .tinted-background {
        width: 100%;
        margin-top: 15px;
        background: #FAFBFF ;
        border-radius: 11px;
        padding: 20px;
    }

    .bgColor:nth-child(odd) {
        background-color: #FFFFFF;
    }


    .skillsEvaluation-parent {
        .radioGroup-row{
            width: 30%;
        }

        .inputTextField-Box{
            display:flex;
        }
    }

    .checkbox-label {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 12px;
        line-height: 124.4%;
        letter-spacing: 0.005em;
        color: #3D3D3D;
        white-space: break-spaces;   
        align-self: center;
    }

    .evaluationBox{
        display: flex;
        flex-direction: row;
        flex: 0.5;
    }

   
    .disable-text {
        opacity: 0.4;
    }

    .radioMargin{
        margin-top: 5px;
    }

    .add-more-instructions {
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 12px;
        line-height: 124.4%;
        letter-spacing: 0.005em;
        color: #123FBC;
    }

    .arrow {
        display: flex;
        flex: 1;
        color: #C7C7C7;
    }

    .imp-checkBox{
        padding: 5px 10px ;
        align-items: flex-start;
    }
    
    .side-text{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 124.4%;
        letter-spacing: 0.005em;
        color: #8E8E8E;
    }

    .arrow-seperator {
        width: 100% !important;
        border-top: 2px solid;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        margin-left: 10px;
        margin-right: -10px;
    }

    .react-select-container {
        width: 75px;
    }

    .mySelect__value-container {
        height: 35px;
    }

    .question-bg {
        background: #FFFFFF;
        border: 1px solid #DBDBDB;
        box-sizing: border-box;
        box-shadow: 0px 2px 20px rgba(13, 50, 112, 0.12);
        border-radius: 11px;
        width: 100%;
        padding: 10px;
    }

    .question-title {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 13px;
        line-height: 124.4%;
        letter-spacing: 0.005em;
        color: #3D3D3D;

        text-indent: -30px;
        margin-left: 30px;
        white-space: break-spaces;

        .bold {
            font-weight: 600;
        }

        .semibold {
            font-weight: 500;
        }
        .light {
            color: #AAAAAA;
        }
    }

    .question-statement
    {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 13px;
        letter-spacing: 0.005em;
        color: #3D3D3D;
        margin-left: 30px;
        white-space: break-spaces;
    }

    .externally-added{
        background: #EFEEEE;
        border-radius: 6px;
        padding: 3px 5px;
        width: max-content;
        white-space:nowrap;
        font-family: 'Poppins';
font-weight: 600;
font-size: 11px;
line-height: 124.4%;
text-align: right;
letter-spacing: 0.005em;
color: #535353;
    }

    .noQuestions{
        background-color: rgba(238, 242, 252, 1);
        padding: 1.5em 2em;
        border-radius: 12px;
        margin-top: 1em;
        display: flex;
        #bannerText{
            margin-top: 0.25em;
            font-size: 22px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: -0.005em;
            text-align: left;
            color:rgba(144, 144, 144, 1);
            #bannerSpan{
                color:rgba(104, 104, 104, 1);
            }
        }
        #emptyBox{
            margin-right: 1em;
            width:36px;
        }

    }
    
    .answer-title {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 11px;
        line-height: 124.4%;
        letter-spacing: 0.005em;
        color: #535353;
        margin-left: 30px;
        .difficulty {
            font-weight: 600;
            color: #0CBC28;
        }
    }
    .w-50{
        width: 50%;
    }

    .ml-10{
        margin-left: 10px;
    }
    .mr-10{
        margin-right: 10px;
    }
    .pointer{
        cursor: pointer;
    }

    .keywords {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 11px;
        line-height: 124.4%;
        letter-spacing: 0.005em;
        color: #AAAAAA;
        margin-top: 10px;
    }
    .things-check{
        width: 100%;
        margin-right: 10px;
    }
    .things-add{
        height: 40px;
        width: 40px;
        margin: 7px 3px;
        color: white;
        background-color:#3D3D3D;
        border-radius: 4px;
        font-size: 25px;
    }
    .things-sub{
        height: 40px;
        width: 40px;
        margin: 7px 3px;
        color: #3D3D3D;
        border: 1px solid #3D3D3D;
        border-radius: 4px;
        font-weight: 500;
        font-size: 23px;
    }

    .button-add {
        background: #123FBC;
        border-radius: 6px;
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 12px;
        line-height: 124.4%;
        letter-spacing: 0.005em;
        color: #FFFFFF;
        text-transform: none;
        width: fit-content;
    }

    .add-information-input {
        width: 80%;
        border-radius: 4px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        padding-left: 5px;
    }

    .add-information-input:-ms-input-placeholder,
    .add-information-input::-webkit-input-placeholder,
    .add-information-input::placeholder {
        color: #858181;
    }

    .add-information-add-btn {
        height: 22px;
        width: 22px;
        background: #123FBC;
        border-radius: 6px;
        color: white;
        cursor: pointer;
        vertical-align: bottom;
    }

    .add-information-cancel-btn {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        text-decoration: underline;
        color: red;
        cursor: pointer;
        vertical-align: bottom;
        color: #BC2612;
    }

    .edit-btn {
        color: #1844AD;
        margin-left: 10px;
        margin-top: 2px;
        font-size: 11px;
    }

    .remove-btn{
        margin-left: 7px;
        margin-top: 2px;
        font-size: 11px;
    }
    
    .add-button{
        width: 28px;
        height: 23px;
        background-color: #D9D9D9;
        border-radius: 4px;
        color: black;
        align-items: flex-end;
        margin-top: 22px;
        margin-left: -30px;
        padding-left: 4px;
        cursor: pointer;
    }
    .tags {
       
        .tag {
            color: white;
            display: flex;
            height: 24px;
            justify-content: space-between;
            margin: 2px;
            padding: 2px 5px;
            border-radius: 6px;
            background-color: #3D3D3D;
            .tag-text {
                font-family: Poppins;
                font-size: 14px;
                margin: 2px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: 0.025em;
                text-align: left;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 90px;
                width: fit-content;
            }
            .tag-cross {
                margin: 4px;
                height: 14px;
                width: 14px;
            }
        }
    }
}